@import "01_setting/_var.scss";
@import "01_setting/_mixin.scss";

/*=============================================================
 404 page not found
=============================================================*/
#page404{ 
	#error{ 
		padding: 20px 15px;
		h1{ 
			margin: 0 0 20px;
			font-size: 2.4rem;
			font-weight: 500;
			text-align: center;
		}
		.txtWrap{ 
			margin: 0 0 10px;
			padding: 15px 20px;
			background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4JpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo1QTlBQjU0RTM4MEZFNTExQUQwMkI0MDZGQTk1QzE2MyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NUQ1MjBCNjE2MjIxMUU4QjQwQkMwQ0NENjI3NzA0MyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NUQ1MjBCNTE2MjIxMUU4QjQwQkMwQ0NENjI3NzA0MyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxNS41IChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOmMzYjY2YWNmLTFiYzItNTQ0Ni1iYmI2LWJjYTc3OGVjZmM0ZiIgc3RSZWY6ZG9jdW1lbnRJRD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOjllNWYxZGM4LTBiMTEtMTFlOC1hMDMyLWI2YTgzMzRkYWUyNSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtCsYUoAAABcSURBVHja7NexEYAwEAPBfw/9d0pGbBt6UADMXgUbKFGv66xIc9aaFWrUK8PCwsLCwsLCwsLCwvo/63j+XaoeOVbqc96mJCtbmzwWFhYWFhYWFhYWFtanWVuAAQBQiwtGKXU3nAAAAABJRU5ErkJggg==)repeat 0 0;
			background-size: 50%;
			font-size: 1.7rem;
			line-height: 1.72;
			text-align: center;
		}
		.note{ 
			margin: 0 0 25px;
			font-size: 1.2rem;
			line-height: 1.67;
		}
	}
	@media all and (min-width: 600px) { 
		#error{ 
			padding: 0;
			h1{ 
				margin: 0 0 28px;
				font-size: 3rem;
				letter-spacing: 0.1em;
			}
			.txtWrap{ 
				width: 810px;
				margin: 0 auto 24px;
				padding: 34px 0;
				font-size: 1.9rem;
				line-height: 1.84;
				letter-spacing: 0.1em;
				background-size: 50px;
			}
			.note{ 
				margin: 0 0 45px;
				font-size: 1.5rem;
				text-align: center;
				letter-spacing: 0.1em;
			}
		}
	}
}